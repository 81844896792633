import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { store } from "./Redux/store/store";
import { Provider } from "react-redux";
import { init as initApm } from "@elastic/apm-rum";
import GlobalToastContainer from "./component/GlobalToastContainer";
import { Box } from "@mui/material";
import { LoaderGif } from "./component/ConstantImagesURL";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
const App = lazy(() => import("./App"));

try {
  const apm = initApm({
    serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
    environment: process.env.REACT_APP_APM_ENVIRONMENT,
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,
    secretToken: process.env.REACT_APP_APM_API_KEY,
    serviceVersion: "1.0.0",
    logLevel: "info",
    breakdownMetrics: true,
    useElasticTraceparentHeader: true,
    transactionSampleRate: 1.0,
  });
  apm.startTransaction("App Load", "app-load");
} catch (error) {
  console.error("Failed to initialize APM:", error);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GlobalToastContainer />
          <CssBaseline />
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <Box>
                  <img
                    src={LoaderGif}
                    alt="Loader Gif"
                    style={{ width: "40%", height: "100%" }}
                    loading="lazy"
                  />
                </Box>
              </Box>
            }
          >
            <App />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
